<template>
  <div class="register-form" :key="step">
    <form ref="registerForm" action="javascript:void(0)">
      <div class="register-step" v-if="step === 1">
        <div class="register-step__title">Номер телефона</div>
        <div class="register-step__description">
          Укажите телефон, чтобы быстро входить на портал по смс-коду.
        </div>
        <TextField
          :type="'tel'"
          :maskString="'+7 (###) ###-##-##'"
          :placeholder="'+7(---) --- -- --'"
          v-model="phoneNumber.value"
          :label="'Номер телефона'"
          :isError="phoneNumber.isError"
          :errorText="phoneNumber.errorText"
          class="mb-8 mb-xs-4"
        >
        </TextField>
        <Checkbox
          class="mb-4"
          :label="`Я даю свое согласие на <a href='#openPolicy'>обработку персональных данных</a>*`"
          v-model="personalData.value"
          :isError="personalData.isError"
          :errorText="personalData.errorText"
        />
        <Checkbox
          class="mb-4"
          :label="`Я подтверждаю, что являюсь сотрудником здравоохранения*`"
          :type="'checkbox'"
          v-model="iAmMedic.value"
          :isError="iAmMedic.isError"
          :errorText="iAmMedic.errorText"
        />
        <Checkbox
          :label="`Я хочу получать рассылку с информацией и полезными материалами по СМС`"
          :type="'checkbox'"
          v-model="subscribePhone"
          class="mb-8"
        />
        <div
          style="height: 0px"
          id="phone-captcha"
          class="smart-captcha"
          data-sitekey="ysc1_P3DAIAdNd6wKe0s9A7BRyxivEeKtDx6DttCQObTF2df6974b"
        ></div>

        <div class="buttons-container">
          <div
            class="register-step__button button_pink button"
            @click="checkPhoneAction"
          >
            Продолжить
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 2">
        <div class="register-step__title">Подтверждение номера телефона</div>
        <div class="register-step__description">
          Отправили смс с кодом на номер +7 {{ phoneNumber.value }}.
          <span class="register-step__description_pink" @click="changePhone"
            >Изменить номер?</span
          >
        </div>
        <TextField
          :type="'tel'"
          v-model="smsCode.value"
          :maskString="'####'"
          :readonly="smsCode.success"
          :label="'Код из смс*'"
          :isError="smsCode.isError"
          :errorText="smsCode.errorText"
          :successState="smsCode.success"
        >
          <GetCode
            v-if="!smsCode.success"
            :refresh="checkPhoneResend"
            class="ml-4 ml-xs-0 mt-xs-4"
          />
        </TextField>

        <div class="d-flex align-center">
          <div
            :class="{ button_disabled: loading }"
            class="register-step__button button_pink button"
            @click="checkPhoneSuccess"
          >
            <span>Продолжить</span>
          </div>
          <Preloader class="mt-10 ml-4" v-if="loading" />
        </div>
      </div>
      <div class="register-step" v-if="step === 3">
        <div class="register-step__title">Ваш email</div>
        <div class="register-step__description">
          Отправим на него письмо с кодом подтверждения
        </div>
        <TextField
          :type="'email'"
          v-model="email.value"
          :isError="email.isError"
          :errorText="email.errorText"
          :label="'Email*'"
          :disabled="noEmail === true"
          class="mb-8"
        />

        <Checkbox
          :label="`Я отказываюсь использовать почту`"
          class="mb-4"
          :type="'checkbox'"
          v-model="noEmail"
        />
        <Checkbox
          :label="`Я хочу получать рассылку с информацией и полезными материалами на Email`"
          :type="'checkbox'"
          v-model="subscribeEmail"
          :disabled="noEmail === true"
        />
        <div
          style="height: 0px"
          id="email-captcha"
          class="smart-captcha"
          data-sitekey="ysc1_P3DAIAdNd6wKe0s9A7BRyxivEeKtDx6DttCQObTF2df6974b"
        ></div>
        <div class="d-flex align-center">
          <div
            class="register-step__button button_pink button"
            @click="checkEmailAction"
            :class="{ button_disabled: loading }"
          >
            {{
              this.snaplogicData &&
              this.email.value === this.snaplogicData.email
                ? "Зарегистрироваться"
                : "Продолжить"
            }}
          </div>
          <Preloader class="mt-10 ml-4" v-if="loading" />
        </div>
      </div>
      <div class="register-step" v-if="step === 4">
        <div class="register-step__title">Ваш email</div>
        <div class="register-step__description">
          Отправили письмо с кодом на {{ email.value }}.
          <span class="register-step__description_pink" @click="changeEmail"
            >Изменить email?</span
          >
        </div>
        <TextField
          :type="'tel'"
          v-model="letterCode.value"
          :maskString="'####'"
          :label="'Код из письма*'"
          :readonly="letterCode.success"
          :isError="letterCode.isError"
          :errorText="letterCode.errorText"
          :successState="letterCode.success"
        >
          <GetCode
            v-if="!letterCode.success"
            :refresh="checkEmail"
            :args="[email]"
            class="ml-4 ml-xs-0 mt-xs-4"
          />
        </TextField>
        <div class="d-flex align-center">
          <div
            :class="{ button_disabled: loading }"
            class="register-step__button button_pink button"
            @click="checkEmailSuccess"
          >
            <span>
              {{ this.snaplogicData ? "Зарегистрироваться" : "Продолжить" }}
            </span>
          </div>
          <Preloader class="mt-10 ml-4" v-if="loading" />
        </div>
      </div>
      <div class="register-step" v-if="step === 5">
        <div class="register-step__title">Личные данные</div>
        <TextField
          :type="'text'"
          v-model="lastName.value"
          :isError="lastName.isError"
          :errorText="lastName.errorText"
          :label="'Фамилия*'"
          class="mb-8 mt-8"
        />
        <TextField
          :type="'text'"
          v-model="name.value"
          :isError="name.isError"
          :errorText="name.errorText"
          :label="'Имя*'"
          class="mb-8"
        />
        <TextField
          :type="'text'"
          v-model="secondName.value"
          :isError="secondName.isError"
          :errorText="secondName.errorText"
          :label="'Отчество*'"
          class="mb-8"
        />
        <Select
          v-model="speciality.value"
          :items="specialitiesList"
          :isError="speciality.isError"
          :errorText="speciality.errorText"
          :searchable="true"
          :label="'Специальность*'"
          :placeholder="'Выбрать специальность из списка'"
          @optionTriggered="changeSpeciality"
        />

        <div
          class="register-step__button button_pink button"
          @click="checkPersonalData"
        >
          Продолжить
        </div>
      </div>
      <div class="register-step" v-if="step === 6">
        <div class="register-step__title">Основное место работы</div>
        <TextField
          :type="'text'"
          v-model="institution.value"
          :isError="institution.isError"
          :errorText="institution.errorText"
          :label="'Учреждение*'"
          class="mb-8 mt-8 mb-xs-4"
        />
        <TextField
          type="address"
          v-model="address.value"
          :isError="address.isError"
          :errorText="address.errorText"
          :label="'Адрес*'"
          class="mb-8 mb-xs-4"
        />
        <TextField
          :type="'text'"
          v-model="department.value"
          :isError="department.isError"
          :errorText="department.errorText"
          :label="'Отделение'"
          class="mb-8 mb-xs-4"
          :disabled="isNotDepartment === true"
        >
          <Checkbox
            :label="`Нет отделения`"
            :type="'checkbox'"
            class="ml-5 ml-xs-0 mt-xs-4"
            v-model="isNotDepartment"
          />
        </TextField>

        <div class="buttons-container">
          <div
            class="register-step__button button_empty-pink back button"
            @click="step = 5"
          >
            Назад
          </div>
          <div
            class="register-step__button button_pink big-button button"
            @click="checkJob"
          >
            Зарегистрироваться
          </div>
        </div>
      </div>
      <div class="register-step" v-if="step === 7">
        <div class="register-step__title">Ответьте на вопросы</div>
        <div
          class="register-step__questions mt-8"
          v-if="
            specialityDetail &&
            specialityDetail.questions &&
            specialityDetail.questions.length
          "
        >
          <div
            class="register-step__question mb-4"
            v-for="(question, ind) in specialityDetail.questions"
            :key="ind"
          >
            <div
              class="register-step__question-title"
              v-html="question.title"
            ></div>
            <div
              class="register-step__question-answer"
              v-for="(answer, aInd) in question.choices"
              :key="aInd"
            >
              <RadioButton
                v-if="question.answer_type == '1'"
                class="mb-4"
                :label="question.choices[aInd].title"
                :inputValue="`${question.choices[aInd].id}`"
                v-model="questionList[ind]"
              />
              <Checkbox
                v-else
                class="mb-4"
                :label="question.choices[aInd].title"
                :inputValue="`${question.choices[aInd].id}`"
                v-model="questionList[ind]"
              />
            </div>
          </div>
        </div>
        <div class="buttons-container">
          <div
            :class="{ button_disabled: !enableSendPersonality }"
            class="register-step__button button_pink button"
            @click="sendPersonalization"
          >
            Ответить
          </div>
          <div
            class="
              register-step__button
              button_empty-pink button
              ml-4 ml-xs-0
              mt-xs-4
            "
            @click="returnToTarget"
          >
            Пропустить
          </div>
        </div>
      </div>
    </form>
    <RegisterFooter>
      <div class="header-auth__sso mt-8 pt-8 mb-8">
        <a
          :href="`https://login.microsoftonline.com/af8e89a3-d9ac-422f-ad06-cc4eb4214314/oauth2/v2.0/authorize?client_id=4801abdb-5bbb-415a-b78f-aa34886ffd31&scope=api://4801abdb.astrazeneca.net/user_impersonation&response_type=token&state=${$route.query.back_url || $route.query.next_url}`"
          class="button_gray button"
        >
          Вход сотрудникам AZ
        </a>
      </div>
    </RegisterFooter>
  </div>
</template>

<script>
import Checkbox from "@/components/form-elements/Checkbox.vue";
import RegisterFooter from "./RegisterFooter.vue";
import TextField from "@/components/form-elements/TextField.vue";
import Select from "@/components/form-elements/Select.vue";
import { mapActions, mapGetters } from "vuex";
import GetCode from "@/components/form-elements/getCode.vue";
import Preloader from "../Preloader.vue";
import RadioButton from "@/components/form-elements/RadioButton.vue";
// import PersonalityTest from "../../components/pageComponents/cabinet/PersonalityTest.vue";
export default {
  components: {
    TextField,
    RegisterFooter,
    Checkbox,
    Select,
    GetCode,
    Preloader,
    RadioButton,
    // PersonalityTest,
  },
  name: "RegisterForm",
  data: () => ({
    questionList: [],
    hasProfiling: false,
    loading: false,
    stepTriggers: [
      {
        screenNumber: 1,
        stepNumber: 2,
      },
      {
        screenNumber: 3,
        stepNumber: 2,
      },
      {
        screenNumber: 5,
        stepNumber: 3,
      },
      {
        screenNumber: 6,
        stepNumber: 4,
      },
      {
        screenNumber: 7,
        stepNumber: 4,
      },
    ],
    letterCode: {
      value: "",
      isError: false,
      errorText: "",
      success: false,
    },
    subscribeEmail: true,
    subscribePhone: true,
    smsCode: {
      value: "",
      isError: false,
      errorText: "",
      success: false,
    },
    name: {
      value: "",
      isError: false,
      errorText: "",
    },
    lastName: {
      value: "",
      isError: false,
      errorText: "",
    },
    secondName: {
      value: "",
      isError: false,
      errorText: "",
    },
    institution: {
      value: "",
      isError: false,
      errorText: "",
    },
    address: {
      value: "",
      isError: false,
      errorText: "",
    },
    department: {
      value: "",
      isError: false,
      errorText: "",
    },
    email: {
      value: "",
      isError: false,
      errorText: "",
    },
    noEmail: false,
    personalData: {
      value: false,
      isError: false,
      errorText: "",
    },
    iAmMedic: {
      value: false,
      isError: false,
      errorText: "",
    },
    speciality: {
      value: [],
      isError: false,
      errorText: "",
    },
    isNotDepartment: false,
    phoneNumber: {
      value: "",
      isError: false,
      errorText: "",
    },
    step: 1,
    userData: {
      first_name: "",
      last_name: "",
      middle_name: "",
      speciality: "",
      organization: "",
      organization_address: "",
      department: "",
    },
    snaplogicData: null,
    tokens: null,
    captcha: {
      phone: {
        id: null,
      },
      email: {
        id: null,
      },
    },
  }),
  computed: {
    ...mapGetters(["Rules", "user", "specialitiesList", "specialityDetail"]),
    emailFromQuery() {
      if (this.$route?.query?.email === this.email) {
        return true;
      }
      return null;
    },
    enableSendPersonality() {
      return (
        [...this.questionList].filter((el) =>
          Array.isArray(el) ? el.length : el
        ).length === this.specialityDetail.questions.length
      );
    },
  },
  methods: {
    ...mapActions([
      "checkEmail",
      "checkEmailCode",
      "checkPhone",
      "checkPhoneResend",
      "checkPhoneCode",
      "registration",
      "changeUserInfo",
      "fetchAlerts",
      "fetchSpecialitiesList",
      "fetchSpecialityDetail",
    ]),
    async changeSpeciality() {
      await this.fetchSpecialityDetail(this.speciality.value[0]);
      if (
        this.specialityDetail &&
        this.specialityDetail.questions &&
        this.specialityDetail.questions.length
      ) {
        this.questionList = [];
        this.fillQuestionsList();
        this.hasProfiling = true;
      } else {
        this.hasProfiling = false;
        this.questionList = [];
      }
    },
    fillQuestionsList() {
      for (let question of this.specialityDetail.questions) {
        if (question.answer_type == "1") {
          this.questionList.push(null);
        } else {
          this.questionList.push([]);
        }
      }
      // question.choices.filter((el) => el.is_correct).length > 1
    },
    async checkPhoneAction() {
      const vm = this;
      if (!this.Rules.isRequired(this.phoneNumber.value)) {
        this.phoneNumber.isError = true;
        this.phoneNumber.errorText = "Некорректный номер";
        return;
      }
      if (!this.Rules.isCorrectPhone(this.phoneNumber.value)) {
        this.phoneNumber.isError = true;
        this.phoneNumber.errorText = "Некорректный номер";
        return;
      }
      if (!this.Rules.isRequired(this.personalData.value)) {
        this.personalData.isError = true;
        this.personalData.errorText =
          "Для регистрации нужно согласие на обработку персональных данных";
        return;
      }
      if (!this.Rules.isRequired(this.iAmMedic.value)) {
        this.iAmMedic.isError = true;
        this.iAmMedic.errorText =
          "Для регистрации нужно подтверждение, что вы работник сферы здравоохранения";
        return;
      }
      if (window.smartCaptcha) {
        window.smartCaptcha.execute(this.captcha.phone.id);
      }
    },
    async phoneCaptchaSuccess(token) {
      let checkPhone = await this.checkPhone({
        phone: this.phoneNumber.value,
        source: this.$route.query.source,
        captcha: token,
      });
      if (checkPhone) {
        window.smartCaptcha.destroy(this.captcha.phone.id);
        if (checkPhone == "login") {
          this.$router.push({
            name: "Login",
            params: {
              phone: this.phoneNumber.value,
              exist: true,
            },
            query: this.$route.query,
          });
        }

        this.step = 2;
      }
    },
    async checkEmailAction() {
      if (this.noEmail) {
        if (this.snaplogicData) {
          this.Register();
        } else {
          this.step = 5;
        }
      }

      if (!this.Rules.isRequired(this.email.value)) {
        this.email.isError = true;
        this.email.errorText = "Для регистрации нужно заполнить Email адрес";
        return;
      }
      if (!this.Rules.isCorrectEmail(this.email.value)) {
        this.email.isError = true;
        this.email.errorText = "Некорректный Email";
        return;
      }
      if (window.smartCaptcha) {
        window.smartCaptcha.execute(this.captcha.email.id);
      }
    },
    async emailCaptchaSuccess(token) {
      let checkEmail = await this.checkEmail({
        email: this.email.value,
        captcha: token,
        sendCode: this.snaplogicData
          ? this.email.value === this.snaplogicData.email
            ? false
            : true
          : true,
        subscribe: this.subscribeEmail,
      });
      if (checkEmail) {
          window.smartCaptcha.destroy(this.captcha.email.id);
        if (checkEmail == "login") {
          this.$router.push({
            name: "Login",
            params: {
              phone: this.phoneNumber.value,
              email: this.email.value,
              exist: true,
            },
            query: this.$route.query,
          });
        }
        if (
          this.snaplogicData &&
          this.email.value === this.snaplogicData.email
        ) {
          this.Register();
        } else {
          this.step = 4;
        }
      }
    },
    async checkPersonalData() {
      if (!this.Rules.isRequired(this.lastName.value)) {
        this.lastName.isError = true;
        this.lastName.errorText = "Укажите вашу фамилию";
        return;
      }
      if (!this.Rules.isRequired(this.name.value)) {
        this.name.isError = true;
        this.name.errorText = "Укажите ваше имя";
        return;
      }
      if (!this.Rules.isRequired(this.secondName.value)) {
        this.secondName.isError = true;
        this.secondName.errorText = "Укажите ваше отчество";
        return;
      }
      if (!this.Rules.isRequired(this.speciality.value.length > 0)) {
        this.speciality.isError = true;
        this.speciality.errorText = "Выберите специальность";
        return;
      }
      this.step = 6;
    },
    async checkJob() {
      if (!this.Rules.isRequired(this.institution.value)) {
        this.institution.isError = true;
        this.institution.errorText = "Укажите учреждение";
        return;
      }
      if (!this.Rules.isRequired(this.address.value)) {
        this.address.isError = true;
        this.address.errorText =
          "Выберите адрес учреждения из выпадающего списка";
        return;
      }
      if (!this.isNotDepartment) {
        if (!this.Rules.isRequired(this.department.value)) {
          this.department.isError = true;
          this.department.errorText =
            "Укажите отделение или нажмите «Нет отделения»";
          return;
        }
      }

      this.Register();
    },
    async checkPhoneSuccess() {
      this.step = 3;
    },
    async checkEmailSuccess() {
      if (this.snaplogicData) {
        this.Register();
        return;
      }
      this.step = 5;
    },
    continueEv() {
      this.step++;
    },
    changeEmail() {
      this.step--;
      this.letterCode.value = "";
      this.letterCode.success = false;
    },
    changePhone() {
      this.step--;
      this.smsCode.value = "";
      this.smsCode.success = false;
    },

    fillUserData() {
      this.userData.first_name = this.name.value;
      this.userData.last_name = this.lastName.value;
      this.userData.middle_name = this.secondName.value;
      this.userData.speciality = this.specialitiesList.find(
        (el) => el.value == this.speciality.value[0]
      ).label;
      this.userData.organization = this.institution.value;
      this.userData.organization_address = this.address.value;
      this.userData.department = this.department.value;
      this.userData.default_photo = "/content/cabinet/default-avatar.svg";
    },
    async Register() {
      const vm = this;
      this.fillUserData();

      this.tokens = await this.registration(this.userData);
      if (this.tokens) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "registration success", {
            "registration success": {
              url: this.$route.fullPath,
            },
          });
        }
        this.fetchAlerts({
          alerts: "Регистрация успешно пройдена",
          type: "notify",
        });
        if (this.hasProfiling) {
          this.step = 7;
        } else {
          this.returnToTarget();
        }
      }
    },
    sendPersonalization() {
      this.$axios("profiling/", {
        method: "POST",
        data: {
          speciality: this.speciality.value[0],
          answers: [...this.questionList]
            .filter((el) => el)
            .map((el, ind) => ({
              question: this.specialityDetail.questions[ind].id,
              choices: Array.isArray(el) ? el : [el],
              template_type: "Регистрация",
            })),
        },
        headers: {
          Authorization: `Bearer ${this.tokens.access_token}`,
        },
      })
        .then((res) => {
          this.returnToTarget();
        })
        .catch((error) => {
          console.log(error);
          this.fetchAlerts({
            alerts: "Произошла непредвиденная ошибка. Попробуйте еще раз",
            type: "error",
          });
        });
    },
    returnToTarget() {
      if (this.tokens) {
        let url;
        let add_query = { ...this.$route.query };
        delete add_query.next_url;
        delete add_query.source;
        try {
          url = new URL(this.$route.query.next_url || this.$route.query.source);
        } catch {
          url = new URL(
            "https://" +
              (this.$route.query.next_url || this.$route.query.source)
          );
        }
        url.search += new URLSearchParams(this.tokens).toString();
        url.search += "&" + new URLSearchParams(add_query).toString();

        setTimeout(() => {
          window.open(url, "_self");
        }, 2000);

        return true;
      }
    },
  },
  async mounted() {
    const vm = this;
    if (this.$route?.query?.email) {
      this.email = this.$route.query.email;
    }
    await this.fetchSpecialitiesList();
    if (window.smartCaptcha) {
      this.captcha.phone.id = window.smartCaptcha.render("phone-captcha", {
        sitekey: "ysc1_P3DAIAdNd6wKe0s9A7BRyxivEeKtDx6DttCQObTF2df6974b",
        invisible: true, // Сделать капчу невидимой
      });
      window.smartCaptcha.subscribe(
        this.captcha.phone.id,
        "success",
        vm.phoneCaptchaSuccess
      );
    }
  },
  watch: {
    step() {
      document.body.scrollIntoView({ behavior: "smooth" });
      let step = this.stepTriggers.find((el) => el.screenNumber === this.step);
      this.$emit("realStep", this.step);
      if (step) {
        let hideLeft = this.step === 7;
        this.$emit("changeStep", step.stepNumber, hideLeft);
      }
      if (this.step === 3) {
        if (window.smartCaptcha) {
          this.$nextTick(() => {
            this.captcha.email.id = window.smartCaptcha.render(
              "email-captcha",
              {
                sitekey:
                  "ysc1_P3DAIAdNd6wKe0s9A7BRyxivEeKtDx6DttCQObTF2df6974b",
                invisible: true, // Сделать капчу невидимой
              }
            );
            window.smartCaptcha.subscribe(
              this.captcha.email.id,
              "success",
              this.emailCaptchaSuccess
            );
          });
        }
      }
      if (this.step === 1) {
        if (window.smartCaptcha) {
          this.$nextTick(() => {
            this.captcha.phone.id = window.smartCaptcha.render(
              "phone-captcha",
              {
                sitekey:
                  "ysc1_P3DAIAdNd6wKe0s9A7BRyxivEeKtDx6DttCQObTF2df6974b",
                invisible: true, // Сделать капчу невидимой
              }
            );
            window.smartCaptcha.subscribe(
              this.captcha.phone.id,
              "success",
              this.phoneCaptchaSuccess
            );
          });
        }
      }
    },
    "phoneNumber.value": function () {
      this.phoneNumber.isError = false;
      this.phoneNumber.errorText = "";
    },
    "personalData.value": function () {
      this.personalData.isError = false;
      this.personalData.errorText = "";
    },
    "iAmMedic.value": function () {
      this.iAmMedic.isError = false;
      this.iAmMedic.errorText = "";
    },
    "smsCode.value": async function () {
      if (this.smsCode.value.length >= 4) {
        let checkPhoneCode = await this.checkPhoneCode({
          code: this.smsCode.value,
          subscribe: this.subscribePhone,
        });
        if (!checkPhoneCode) {
          this.smsCode.isError = true;
          this.smsCode.errorText = "Неверный код";
          return;
        } else {
          this.smsCode.isError = false;
          this.smsCode.success = true;
          this.smsCode.errorText = "";
          this.loading = true;
          this.$axios("get-data-from-snaplogic/", {
            method: "POST",
            data: {
              phone: this.phoneNumber.value,
            },
          })
            .then((res) => {
              this.snaplogicData = res.data;
              this.loading = false;
              this.name.value = this.snaplogicData.first_name;
              this.lastName.value = this.snaplogicData.last_name;
              this.secondName.value = this.snaplogicData.middle_name;
              this.institution.value = this.snaplogicData.organization;
              this.address.value = this.snaplogicData.organization_address;
              this.email.value = this.snaplogicData.email;
              this.speciality.value[0] = this.specialitiesList.find(
                (el) =>
                  el.label.toLowerCase() ===
                  this.snaplogicData.speciality.toLowerCase()
              ).value;
              this.department.value = this.snaplogicData.role;
              this.changeSpeciality();
            })
            .catch((error) => {
              this.loading = false;
              if (error && error.request && error.request.status !== 404) {
                this.changePhone();
                this.fetchAlerts({
                  alerts: "Произошла непредвиденная ошибка. Попробуйте еще раз",
                  type: "error",
                });
              }
            });
        }
      }
      this.smsCode.isError = false;
      this.smsCode.errorText = "";
    },
    "email.value": function () {
      this.email.isError = false;
      this.email.errorText = "";
    },
    noEmail: function () {
      this.email.isError = false;
      this.email.errorText = "";
    },
    "letterCode.value": async function () {
      if (this.letterCode.value.length >= 4) {
        let checkEmailCode = await this.checkEmailCode({
          code: this.letterCode.value,
          subscribe: this.subscribeEmail,
        });
        if (!checkEmailCode) {
          this.letterCode.isError = true;
          this.letterCode.errorText = "Неверный код";
          return;
        } else {
          this.letterCode.isError = false;
          this.letterCode.success = true;
          this.letterCode.errorText = "";
          if (!this.snaplogicData) {
            this.loading = true;
            this.$axios("get-data-from-snaplogic/", {
              method: "POST",
              data: {
                email: this.email.value,
              },
            })
              .then((res) => {
                this.snaplogicData = res.data;
                this.loading = false;
                this.name.value = this.snaplogicData.first_name;
                this.lastName.value = this.snaplogicData.last_name;
                this.secondName.value = this.snaplogicData.middle_name;
                this.institution.value = this.snaplogicData.organization;
                this.address.value = this.snaplogicData.organization_address;
                this.phoneNumber.value = this.snaplogicData.phone.replace(
                  "+7",
                  ""
                );
                this.speciality.value[0] = this.specialitiesList.find(
                  (el) =>
                    el.label.toLowerCase() ===
                    this.snaplogicData.speciality.toLowerCase()
                ).value;
                this.department.value = this.snaplogicData.role;
                this.changeSpeciality();
              })
              .catch((error) => {
                this.loading = false;
                if (error && error.request && error.request.status !== 404) {
                  this.changeEmail();
                  this.fetchAlerts({
                    alerts:
                      "Произошла непредвиденная ошибка. Попробуйте еще раз",
                    type: "error",
                  });
                }
              });
          }
        }
      }
      this.letterCode.isError = false;
      this.letterCode.errorText = "";
    },
    "lastName.value": function () {
      this.lastName.isError = false;
      this.lastName.errorText = "";
    },
    "name.value": function () {
      this.name.isError = false;
      this.name.errorText = "";
    },
    "secondName.value": function () {
      this.secondName.isError = false;
      this.secondName.errorText = "";
    },
    "speciality.value": function () {
      this.speciality.isError = false;
      this.speciality.errorText = "";
    },
    "institution.value": function () {
      this.institution.isError = false;
      this.institution.errorText = "";
    },
    "address.value": function () {
      this.address.isError = false;
      this.address.errorText = "";
    },
    "department.value": function () {
      this.department.isError = false;
      this.department.errorText = "";
    },
    isNotDepartment: function () {
      this.department.isError = false;
      this.department.errorText = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  margin: 0 auto;
  width: 592px;

  @media screen and (max-width: 1220px) {
    width: 100%;
    margin: 40px 0 0;
  }
}

.register-step {
  &__title {
    margin-bottom: 16px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #3c4242;

    &_pink {
      text-decoration-line: underline;
      color: #d0006f;
      cursor: pointer;
    }
  }
  &__button.back {
    width: 92px;
    margin-right: 16px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 16px;
      margin-right: 0;
    }
  }
  &__button {
    margin-top: 32px;
    width: fit-content;
    height: 46px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .big-button {
    width: 229px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .buttons-container {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }
  .show-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #3c4242;
    cursor: pointer;

    &_active {
      color: #d0006f;
    }
  }

  &__question {
    &-title {
      margin-bottom: 16px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1f1f1f;
    }
  }
}
</style>

<style lang="scss">
.req__list {
  list-style: disc;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width: 350px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  .req__point {
    margin-right: 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #b2b4b4;
    list-style-position: inside;
    &:last-child {
      margin-right: 0;
    }
    &.error {
      color: #cb4050;
    }
  }
}

.req__title {
  margin: 8px 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #3c4242;
}

.pass-block {
  .textfield__input-success {
    right: 30px;
  }
}

.register-step {
  &__refresh-code {
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
